// components/Router.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/index';
import About from '../pages/about';
import Services from '../pages/services';
import Contact from '../pages/contact';

const AppRouter = () => {
  const [activePage, setActivePage] = useState('home');

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
