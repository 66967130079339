// components/Contact/LocationDetails.js
import React from 'react';
import { Col, Row } from 'reactstrap';

const LocationDetails = () => (
  <Col md={{ size: 12, order: 2 }} lg={{ size: 6, order: 1 }} className="location-column">
    <Row className="contact-row">
        <Col>
            <address>
                <h3>Address</h3>
                <p>Suite 111 - 1011 Glenmore Trail SW<br/>
                Calgary, AB T2V 4R6</p>
            </address>
            <div>
                <h3>Phone</h3>
                <p>403-252-4140</p>
            </div>
        </Col>
        <Col>
            <h3>Hours</h3>
            <p>Monday-Thursday: 8:00AM - 4:00PM</p>
            <p>Friday: 8:00AM - 12:00PM</p>
            <p>Evenings and weekends by appointment only</p>
        </Col>
    </Row>
    <Row>
        <Col className='maps-colum'>
            <iframe
                className='pt-2'
                title="Google Maps Location"
                width="100%"
                height="550"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2511.2149485328346!2d-114.08804992221903!3d50.99369997170104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537171a6311aef85%3A0xb7dbe5ca94d9757a!2sPrecision%20Denture%20Clinic!5e0!3m2!1sen!2sus!4v1704558830476!5m2!1sen!2sus"  
                allowFullScreen>  
            </iframe>
        </Col>
    </Row>
  </Col>
);

export default LocationDetails;
