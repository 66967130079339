// ListItem.js
import React from 'react';
import bulletIcon from "../assets/icons/tooth-bullet.svg";


const ListItem = ({altText, children }) => (
  <li>
    <img src={bulletIcon} alt={altText} className="img-fluid px-2" />
    {children}
  </li>
);

export default ListItem;
