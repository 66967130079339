// components/ContactSection.js
import React from 'react';
import { Container } from 'reactstrap';

const ContactSection = () => (
  <div className="text-center p-5">
    <Container>
      <h2>We offer no obligation initial denture care consultation</h2>
      <p>
        For more details don't hesitate to call: <a href="tel:4032524140" className="text-decoration-none text-dark"><strong>403-252-4140</strong></a> 
        or e-mail: <a href="mailto: contact@precisiondentureclinic.ca" className="text-decoration-none text-black"><strong>contact@precisiondentureclinic.ca</strong></a>
      </p>
      <a href="/contact" tag="a"><button className="btn-gradient"><h4>Make an Appointment</h4></button></a>
    </Container>
  </div>
);

export default ContactSection;
