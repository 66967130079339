// ConsultForm.js
import React, { useState } from 'react';
import { FormGroup, Label, Input, Alert, Row, Col } from 'reactstrap';
import FormSubmit from '../FormSubmit';

const ContactForm = () => {
  const { form, formData, setFormData, handleSubmit, resetForm } = FormSubmit();
  
  const [alert, setAlert] = useState(null);

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => {
      setAlert(null);
    }, 5000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleSubmit();
      showAlert('success', 'Form submitted successfully!');
      resetForm();
    } catch (error) {
      showAlert('danger', 'Form submission failed!');
      console.error(error.text);
    }

    console.log('Form submitted:', formData);
  };

  return (
    <div className='shadow' style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '5px', display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <h2 className='text-center'> Contact Us</h2>
        {alert && <Alert color={alert.type}>{alert.message}</Alert>} 
        <form onSubmit={handleFormSubmit} ref={form}>
          <FormGroup floating>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <Label for="name">Name*</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Label for="email">Email*</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              type="tel"
              id="phone"
              name="phone"
              placeholder="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <Label for="phone">Phone*</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              type="textarea"
              id="message"
              name="message"
              placeholder="message"
              value={formData.message}
              onChange={handleChange}
              required
              style={{ width: '100%', height: '10em'}}
            />
            <Label for="message">Message*</Label>
          </FormGroup>
          <div style={{ flex: 1 }}></div>
          <FormGroup className='text-center'>
            <button type="submit" className="btn-gradient"><h4>Submit</h4></button>
          </FormGroup>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
