import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => (
  <footer className="footer bg-success bg-gradient">
    <Container className='text-black'>
      <Row className='ms-auto pt-5'>
        <Col sm={5} md={3} lg={4} className='text-center'>
          <h3>Address</h3>
          <p>
            Suite 111 - 1011 Glenmore Trail SW 
            <br/>
            Calgary, AB T2V 4R6 
            <br/> 
            Located in the Rockyview Health Center
            </p>
          <h3>Phone</h3>
          <p>403-252-4140</p>
        </Col>
        <Col sm={5} md={3}  lg={3} className='text-center'>
          <img src={require('../assets/images/logo-full.png')} alt='Precision Denture Clinic Logo' style={{ width: '100%'}} />
          <a href="/contact" tag="a"><button className="btn-gradient my-3"><h4>Contact</h4></button></a>
        </Col>
        <Col sm={4} md={5} lg={5} className='text-center'>
          <h3>Hours</h3>
          <p>
            <label>Monday<br/>Tuesday<br/>Wednesday<br/>Thursday<br/>Friday</label>
            <label className='ps-2'>8:00am - 4:00pm<br/>8:00am - 4:00pm<br/>8:00am - 4:00pm<br/>8:00am - 4:00pm<br/>8:00am - 12:00pm</label>
            <br/>
            Evenings and weekends by appointment only
          </p>
        </Col>
      </Row>
      <Row className='pb-5'>
        <Col>
          <div className="footer-column copyright text-center">
            <p>&copy; 2023 Precision Denture Clinic (Calgary) | All rights reserved | Web Design & SEO by Bryce Wiedemann</p>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
