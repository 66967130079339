// components/ServiceCard.js
import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

const ServiceCard = ({ service, onClick }) => (
  <Card
    className="service-card shadow"
    onClick={onClick}
    style={{ height: '100%' }}
  >
    <CardBody className="service-overlay text-center p-1">
      <img src={require(`../../assets/images/denture${service.id}.jpg`)} alt={`Denture ${service.id} Image`} width="100%" />
      <img src={require(`../../assets/icons/denture${service.id}.svg`)} alt={`Denture ${service.id} Icon`} height="15%" style={{marginTop: '-10%'}}/>
      <CardTitle className="p-3">
        <b>{service.title}</b>
        <p>{service.tag}</p>
      </CardTitle>
    </CardBody>
  </Card>
);

export default ServiceCard;
