// components/PrecisionDentureClinicSection.js
import React from 'react';
import { Row, Col } from 'reactstrap';

const PrecisionDentureClinicSection = () => (
  <>
    <Row style={{ paddingInline: '1em' }}>
      <Col></Col>
      <Col xs={12} md={6}><h2>Precision Denture Clinic</h2></Col>
    </Row>
    <Row style={{ padding: '1em' }} className='bg-success bg-gradient bg-opacity-25 rounded-tl-br align-items-center shadow-sm'>
        <Col xs={12} md={6}>
            <video
            src={require("../../assets/videos/PDC-Video.mp4")}
            alt='Video walkthough of Precision Denure Clinic'
            controls
            muted
            autoPlay
            loop
            className='img-fluid shadow'
            style={{ width: '100%', height: 'auto' }}
            />
        </Col>
        <Col xs={12} md={6}>
            <div>
            <h4>Est. 1987</h4>
            <p>
              Welcome to Precision Denture Clinic, a longstanding establishment founded by dedicated denturist Scott Fairbanks in 1987,
              extending over 30 years of exceptional denture services to the Calgary community. Our primary objective is to restore missing 
              teeth and ensure comprehensive oral health through personalized treatment plans. Our friendly staff facilitates seamless 
              interactions with dental insurance, employing top-notch materials tailored to each patient's unique needs. As a denture 
              specialist, Mr. Fairbanks prioritizes patient education, actively addressing concerns and guiding individuals through 
              the nuanced process of crafting their new dentures. In addition to our commitment to oral health, we offer a curated selection 
              of quality products to enhance and maintain your new smile. Discover the heightened level of personalized care and attention 
              to detail at Precision Denture Clinic, where comfort, functionality, and a natural appearance are our paramount considerations.
            </p>
            </div>
        </Col>
    </Row>
  </>
);

export default PrecisionDentureClinicSection;
