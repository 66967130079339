// FormSubmit.js
import { useState, useRef } from 'react';
import emailjs from "@emailjs/browser";

const FormSubmit = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleSubmit = () => {
    return emailjs.sendForm("service_ciu99go", "template_8cihbyj", form.current, "tBBm3cW7nVSPR0VrK");
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  return {
    form,
    formData,
    setFormData,
    handleSubmit,
    resetForm,
  };
};

export default FormSubmit;
