// components/Services.js
import React from 'react';
import { Container, Row, Col, List } from 'reactstrap';
import ListItem from '../ListItem';

const Services = () => (
  <div className="bg-success bg-gradient">
    <Container>
      <Row className="align-items-center">
        <Col xs={12} md={5}>
          <img src={require("../../assets/images/toothModel.jpg")} alt="Person holding tooth figure" className="img-fluid" />
        </Col>
        <Col className='pb-3'>
          <h2>Services</h2>
          <p>Some text about services and us having everything you need</p>
          <List type="unstyled">
            <ListItem altText="Tooth icon used as bullet point"><b>Complete Dentures</b></ListItem>
            <ListItem altText="Tooth icon used as bullet point"><b>Partial Dentures</b></ListItem>
            <ListItem altText="Tooth icon used as bullet point"><b>Denture Repairs</b></ListItem>
            <ListItem altText="Tooth icon used as bullet point"><b>Denture Relines</b></ListItem>
            <ListItem altText="Tooth icon used as bullet point"><b>Denture Implants</b></ListItem>
            <ListItem altText="Tooth icon used as bullet point"><b>Denture Cleaning</b></ListItem>
          </List>
          <a href="/services" tag="a"><button className="btn-gradient"><h4>More Info</h4></button></a> 
        </Col>
      </Row>
    </Container>
  </div>
);

export default Services;
