// components/AboutUs.js
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const AboutUs = () => (
    <Container>
    <Row className='py-3'>
        <Col>
          <h2>Professional Dentures in Calgary</h2>
          <p>
            Every smile is a masterpiece, unique and irreplaceable.
            At Precision Denture Clinic, we understand that no two individuals have the same denture needs.
            With 30 years of dedicated service in Calgary, we take pride in delivering personalized,
            high-quality customer care to ensure you receive dentures that are tailored to your exact requirements.
          </p>
        </Col>
      </Row>
      <Row className='bg-success bg-gradient bg-opacity-50 mb-5 rounded-tl-br align-items-center shadow-sm pt-2'>
        <Col md={7}>
          <h3>Comprehensive Denture Solutions</h3>
          <p>
            From seamless denture repairs to advanced dentures attached to implants, our expertise covers a spectrum of denture services.
            We specialize in addressing the unique needs of adults, providing top-tier solutions for complete or partial dentures.
            At Precision Denture Clinic, our commitment to excellence ensures that you receive the highest quality service every time.
          </p>
        </Col>
        <Col >
         <img src={require("../../assets/images/dentureOffice.jpg")} alt='Man in Denture Office' className="img-fluid rounded-circle p-4"/>
        </Col>
      </Row>
      <Row className='bg-primary bg-gradient bg-opacity-25 rounded-tl-br align-items-center shadow-sm'>
        <Col>
          <img src={require("../../assets/images/yourSmile.jpg")} alt='Happy father and son smiling' className="img-fluid rounded-circle p-4"/>
        </Col>
        <Col md={7}>
          <h3>Your Smile, Our Priority</h3>
          <p>
            Experience the freedom of a radiant smile with Precision Denture Clinic. 
            We offer free consultations to understand your specific needs better. 
            Our flexible scheduling, including evening and weekend appointments, as well as house calls, ensures that your dental care fits into your lifestyle seamlessly. 
            When you choose Precision Denture Clinic, expect not just personalized service but also a beautiful and confident smile after your dentures are expertly crafted.
          </p>
        </Col>
      </Row>
      <p>
        <em>Appointments are required for personalized service. Call <a href="tel:4032524140" className="text-decoration-none"><strong>403-252-4140</strong></a> to schedule your appointment.</em>
      </p>
    </Container>
);

export default AboutUs;
