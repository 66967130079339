// pages/index.js
import React, { useEffect } from 'react';
import Header from '../components/Index/Header';
import AboutUs from '../components/Index/ClinicOverview';
import Services from '../components/Index/ServicesOffered';
import PaymentInfo from '../components/Index/PaymentInfo';
import EmergencyDentureCare from '../components/Index/EmergencyCare';
import ContactSection from '../components/ContactUs';

const Home = () => {
  useEffect(() => {
    document.title = 'Precision Denture Clinic - Your Smile, Our Priority';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Welcome to Precision Denture Clinic - Your trusted partner for personalized denture solutions. Achieve a confident and healthy smile with our expert services.';
    document.head.appendChild(metaDescription);
  }, []);
  return (
    <>
      <Header />
      <AboutUs />
      <Services />
      <PaymentInfo />
      <EmergencyDentureCare />
      <ContactSection />
    </>
  );
  };

export default Home;
