// components/ServiceModal.js
import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ServiceDetails from './ServiceDetails';

const ServiceModal = ({ isOpen, toggle, selectedService }) => {
  const details = ServiceDetails[selectedService?.id];

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="bg-primary bg-gradient bg-opacity-25 text-center">
        {selectedService && (
          <>
            <img
              src={require(`../../assets/icons/denture${selectedService.id}.svg`)}
              alt={`Denture ${selectedService.id} Icon`}
              style={{ width: '50px', height: '50px', marginRight: '10px' }}
            />
            {selectedService.title}
          </>
        )}
      </ModalHeader>
      <ModalBody>
        {details && (
          <div>
            {details.details.map((section, index) => (
              <div key={index}>
                <h5>{section.title}</h5>
                <p>{section.content}</p>
              </div>
            ))}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ServiceModal;
