// components/Contact/ContactHeader.js
import React from 'react';
import { Container } from 'reactstrap';

const ContactHeader = () => (
  <div className="position-relative contact-header-container">
    <div className="contact-header-background shadow"></div>
    <div className="overlay"></div>
    <Container className="text-light position-absolute top-50 start-50 text-center translate-middle">
        <h2 className='pb-2'>Contact Us to Schedule an Appointment</h2>
        <p>
          Precision Denture Clinic would love to hear from you!
          <br/> 
          To schedule an appointment for service, please call the office or use the form found on this page to send an email.
        </p>    
    </Container>
  </div>
);

export default ContactHeader;
