// pages/about.js
import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import AboutScott from '../components/About/AboutScott';
import AboutClinic from '../components/About/AboutClinic';
import ContactSection from '../components/ContactUs';

const About = () => {
  useEffect(() => {
    document.title = 'About Precision Denture Clinic';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Learn about Scott Fairbanks and Precision Denture Clinic, providing quality denture services in Calgary since 1987.';
    document.head.appendChild(metaDescription);
  }, []);

  return (
    <Container className='ourStory'>
    <AboutScott />
    <AboutClinic />
    <ContactSection />
  </Container>
  );
};

export default About;
