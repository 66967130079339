// pages/App.js
import React from 'react';
import NavBar from './components/Nav';
import Footer from './components/Footer';
import ToTop from './components/ToTopButton';
import AppRouter from './components/Router';
import './styles/globals.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <NavBar />
      <AppRouter />
      <Footer />
      <ToTop />
    </>
  );
}

export default App;