// components/EmergencyDentureCare.js
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const EmergencyDentureCare = () => (
  <div className="bg-success bg-gradient p-5">
    <Container className="bg-white">
      <div className="text-center">
        <img src={require("../../assets/images/dentureEmergency.jpg")} alt="Tooth Emergency Photo" className="img-fluid pt-5" width="50%" />
      </div>
      <div className="px-5 pt-5">
        <h2 className="m-auto"> Help When You Need It Most:<br /> Our Commitment to Emergency Denture Care</h2>
        <div className="border border-3 border-success w-25 my-3"> </div>
        <p>
          Denture emergencies can happen when you least expect them. At Precision Denture Clinic, we understand the discomfort and inconvenience these situations can cause. Rest assured, we are here to provide swift and reliable solutions to address your denture emergencies. If you find yourself facing a denture emergency, don't hesitate to get in touch with us <b>immediately</b>. Contact Precision Denture Clinic, and our experienced team will do their best to accommodate you as soon as possible. We're dedicated to providing efficient and effective denture care, ensuring you can smile confidently again in no time.
        </p>
      </div>
      <Row className="text-center pb-5">
          <Col xs={12} sm={6}>
            <a href="/contact" tag="a"><button className="btn-gradient mt-3"><h4>Contact us</h4></button></a>           
          </Col>
          <Col>
            <a href="https://www.google.com/maps/search/?api=1&query=50.9937%2C-114.085475,17&query_place_id=ChIJhe8aMaZxcVMRenXZlMrl27c" tag="a"><button className="btn-gradient mt-3"><h4>Directions</h4></button></a>           
          </Col>
      </Row>
    </Container>
  </div>
);

export default EmergencyDentureCare;
