// components/Contact/PaymentOptions.js
import React from 'react';
import { Col } from 'reactstrap';
import mastercardIcon from "../../assets/icons/mastercard.svg";
import visaIcon from "../../assets/icons/visa.svg";
import amexIcon from "../../assets/icons/amex.svg";


const PaymentOptions = () => (
  <>
    <h3>PAYMENT OPTIONS AVAILABLE</h3>
    <Col xs={12} sm={9}>
        <p className='pb-2'>
            We accept several methods of payment for denture services. 
            We will work with you to find an affordable way to pay for your dentures. 
            Payment is accepted in the form of Card, cheques or cash. 
            Affordable payment plans are available. 
            Precision Denture Clinic will also submit insurance papers on your behalf.
        </p>
    </Col>
    <Col sm={{ size: 2, order: 2 }} className='pb-2 justify-content-center'>
        <img src={mastercardIcon} alt='MasterCard Logo' className="logo"/>
        <img src={visaIcon} alt='Visa Logo' className="logo"/>
        <img src={amexIcon} alt='AmEx Logo' className="logo"/>
    </Col>
  </>
);

export default PaymentOptions;
