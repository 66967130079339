// components/PaymentInfo.js
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import insuranceIcon from "../../assets/icons/insurance.svg";
import HealthinsuranceIcon from "../../assets/icons/health-insurance.png";

const PaymentInfo = () => (
  <Container>
    <Row className="border border-4 border-primary border-opacity-50 my-5 rounded-tl-br">
      <Col className="p-5" xs={12} md={6}>
        <img src={insuranceIcon} alt="Insurance Icon" className="" width="10%" />
        <h5><u>PAYMENT INFO</u></h5>
        <h2>Understanding Your Coverage</h2>
        <p>
          Navigating insurance coverage for denture services can be complex, as each individual's plan is unique. At Precision Denture Clinic, we recognize the diversity of insurance policies and their varying terms. Our team can assess your specific insurance plan and estimate your potential out-of-pocket expenses.
          <br />
          <br />
          Contact us at <a href="tel:4032524140" className="text-decoration-none"><strong>403-252-4140</strong></a>.
        </p>
      </Col>
      <Col className="bg-success bg-gradient bg-opacity-50 rounded-br px-4 pt-4">
        <div className="m-4">
          <h2>Payment Options</h2>
          <p>
            Precision Denture Clinic in Calgary accepts several methods of payment for denture services. We will work with you to find an affordable way to pay for your dentures. Payment is accepted in the form of Visa, MasterCard, cheques and cash. Affordable payment plans are available. Precision Denture Clinic will also submit insurance papers on your behalf.
          </p>
        </div>
        <div className="text-center">
          <img src={HealthinsuranceIcon} alt="Alt Insurance Icon" className="" width="60%" />
        </div>
      </Col>
    </Row>
  </Container>
);

export default PaymentInfo;
