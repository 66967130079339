// components/Header.js
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ConsultForm from './ConsultForm';

const Header = () => (
  <div className="home-container">
    <div className="position-relative home-header-container">
      <div className="home-header-background shadow">
      <Container>
        <Row className='pt-5'/>
        <Row className='pt-5 align-items-center'>
          <Col xs={12} md={6} className='px-0'>
            <div className="rounded bg-dark bg-opacity-75 text-light p-3">
              <p className='m-0'>Call for a COMPLIMENTARY assessment: <a href="tel:4032524140" className="text-decoration-none text-success"><strong>403-252-4140</strong></a></p>
              <div className='border border-3 border-success w-25 mb-2'> </div>
              <h3>
                From quick relines and repairs to full, partial, and implant dentures.
                We can help you achieve optimal oral health!
              </h3>
            </div>
          </Col>
          <Col xs={1} style={{height: '5em'}}></Col>
          <Col className="rounded bg-light bg-opacity-75 text-dark text-center " xs={12} md={5} lg={4}>
              <ConsultForm/>
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  </div>
);

export default Header;
