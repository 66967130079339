// serviceDetails.js
const serviceDetails = {
    1: {
        title: 'Complete and Partial Dentures',
        details: [
          {
            title: 'RENEWED CONFIDENCE AND FUNCTION',
            content: 'Do you have missing teeth? Dentures restore all or some of your missing teeth and their function, so you can get back to your regular lifestyle.' +
            ' Precision Denture Clinic offers complete and partial dentures in Calgary, so you can eat the foods you want without experiencing sore spots. Dentures also allow you to smile and laugh again with renewed confidence.',
          },
          {
            title: 'CUSTOM-MADE DENTURES FOR YOU',
            content: 'Your dentures will be measured to fit your mouth the same day your teeth are removed. Dentures can be made quickly if it’s an emergency situation, but it usually takes about two weeks to receive your completed dentures.' +
            ' In addition to fabricating partial and complete dentures in Calgary, we also carry denture maintenance and cleaning products, including denture brushes and cleaners, at my clinic.',
          },
        ],
        image: 'denture1.png',
      },
    2: {
        title: 'Repairs and Relines',
        details: [
            {
                title: 'DENTURE REPAIRS WHILE YOU WAIT',
                content: 'If your dentures become cracked or broken, denture repairs should be sought as quickly as possible or the damage can get worse. Dentures can be repaired while you wait at Precision Denture Clinic, usually within an hour.' +
                ' When it comes to denture repairs, Calgary residents rely upon me for my fast turnaround times and efficient customer service.',
            },
            {
                title: 'WE OFFER QUICK TURNAROUND TIMES FOR RELINES',
                content: 'It’s not unusual for the shape of your mouth to change over time, causing your dentures to no longer fit comfortably. A denture reline is sometimes necessary to refit the inside of your dentures to your mouth to' +
                ' prevent damage to your mouth and/or jaw. Your dentures’ fit should be checked regularly to ensure optimal oral health. At Precision Denture Clinic, same-day denture relines in Calgary are possible while you wait.' +
                ' Please contact me today for more details or to schedule an appointment',
            },
        ],
        image: '../images/denture2.png',
      },
    3: {
        title: 'Denture Implants',
        details: [
            {
                title: 'INCREASED SECURITY AND COMFORT',
                content: 'Dentures that are attached to implants offer you increased security, retention and an optimal fit. This means you will still be able to enjoy the foods that you want (even tougher foods) while avoiding a potentially' +
                ' embarrassing or awkward social situation. Using the latest techniques and materials available, Precision Denture Clinic is proud to offer safe, same-day dentures on implants in Calgary.',
            },
            {
                title: 'SCHEDULE A CONSULTATION TODAY',
                content: 'If you’re suffering from an ill-fitting denture, dentures on implants are a great way to solve the problem. Contact Precision Denture Clinic to see if you’d be a good candidate for dentures on implants in Calgary' +
                ' and/or to schedule a consultation today. House calls are also available for your convenience.',
            },
        ],
        image: 'denture3.png',
      },
    4: {
        title: 'Denture Cleaning',
        details: [
            {
                title: 'MAINTAINING GOOD ORAL HYGIENE',
                content: 'Taking care of your dentures is extremely important to maintain them for optimal oral health and hygiene. At Precision Denture Clinic, we offer professional denture cleaning in Calgary,' +
                ' or we can provide you with the products to clean them yourself at home in-between visits to the clinic. From denture brushes to cleaners, get equipped for daily dentures maintenance.' +
                ' You should treat your dentures just like you would your natural teeth, remembering to clean them every night and after meals. However, be sure to avoid harsh bleaching products and hot water –' +
                ' these can do significant damage to your dentures.',
            },
            {
                title: 'VISIT PRECISION DENTURE CLINIC REGULARLY',
                content: 'It’s important to pay me a visit regularly to ensure that your dentures are fitting properly and being properly maintained. Contact Precision Denture Clinic to schedule denture cleaning in Calgary.' +
                ' We will clean and/or polish your dentures while you’re here for a regular check-up.',
            },
        ],  
        image: 'denture4.png',
      }
  };
  
  export default serviceDetails;
  