// components/AboutSection.js
import React from 'react';
import { Col, Row } from 'reactstrap';

const AboutSection = () => (
  <div className='ourStory pt-3'>
    <h2>Meet Scott Fairbanks</h2>
    <Row style={{ padding: '1em' }} className='bg-primary bg-gradient bg-opacity-25 rounded-tl-br shadow-sm'>
        <Col  xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
            <div>
                <h4>OVER 30 YEARS’ EXPERIENCE OF SERVICE AS YOUR LICENSED DENTURIST</h4>
                <p>
                Scott Fairbanks, an accomplished denturist, graduated with Honors from the Denturist Technology Program at N.A.I.T. in 1985, 
                marking the commencement of a career dedicated to providing top-notch denture services. With a wealth of experience spanning 
                over 30 years, he established Precision Denture Clinic in Calgary, where his primary focus is on aiding patients in achieving 
                improved oral health and radiant smiles. Actively engaged in the dental community, Scott is a member of both the College of 
                Alberta Denturists and the Denturist Association of Alberta, ensuring he stays informed about the latest advancements in his 
                field. Beyond his professional commitment, Scott, alongside his wife, oversees a blended family comprising seven married children 
                and fourteen grandchildren. Acknowledged in the Calgary community for his reliable denture services, Scott's personalized approach
                guarantees client satisfaction, fostering enduring relationships and referrals. Discover the transformative impact of tailored care
                at Precision Denture Clinic, where your denture journey receives the attention it deserves.
                </p>
            </div>
        </Col>
        <Col  xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
            <img src={require("../../assets/images/ScottFairbanks.jpg")} alt='Scott Fairbanks: Denturist' className="img-fluid shadow" />
        </Col>
    </Row>
  </div>
);

export default AboutSection;
