// ConsultForm.js
import React, { useState } from 'react';
import { FormGroup, Label, Input, Alert, Row, Col } from 'reactstrap';
import FormSubmit from '../FormSubmit';

const ConsultForm = () => {
  const { form, formData, setFormData, handleSubmit, resetForm } = FormSubmit();
  
  const [alert, setAlert] = useState(null);

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => {
      setAlert(null);
    }, 5000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await handleSubmit();
      showAlert('success', 'Form submitted successfully!');
      resetForm();
    } catch (error) {
      showAlert('danger', 'Form submission failed!');
      console.error(error.text);
    }

    console.log('Form submitted:', formData);
  };

  return (
    <form onSubmit={handleFormSubmit} className='p-2' ref={form}>
        <h2>Book Free Consultation</h2>
        {alert && <Alert color={alert.type}>{alert.message}</Alert>} 
        <Row >
            <Col>
                <FormGroup className='rounded border border-dark' >
                    <Label for="name" hidden>Full Name</Label>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Full Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        style={{backgroundColor: 'transparent', border: 'none'}}
                    />
                </FormGroup>
                <FormGroup className='rounded border border-dark'>
                    <Label for="name" hidden>Full Name</Label>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        style={{backgroundColor: 'transparent', border: 'none'}}
                    />
                </FormGroup>
                <FormGroup className='rounded border border-dark'>
                    <Label for="phone" hidden>Phone Number</Label>
                    <Input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        style={{backgroundColor: 'transparent', border: 'none'}}
                    />
                </FormGroup>
                <FormGroup className='rounded border border-dark'>
                    <Label for="message" hidden>Message:</Label>
                    <Input
                    type="textarea"
                    id="message"
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    style={{backgroundColor: 'transparent', border: 'none', width: '100%', height: '10em'}}
                    />
              </FormGroup>
                <FormGroup>
                    <button type="submit" className="btn-gradient"><h4>Submit</h4></button>
                </FormGroup>
            </Col>
        </Row>
    </form>
  );
};

export default ConsultForm;
