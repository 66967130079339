import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavLink, NavItem } from 'reactstrap';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color='success bg-gradient' fixed='top' expand='lg' container>
      <NavbarBrand href="/">
        <img src={require(`../assets/images/logo-full.png`)} alt='Precision Denture Clinic Logo' className="navBrandLogo" />
    </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className='ms-auto text-center' navbar>
          <NavItem className='pt-2'>
            <NavLink href="/"><b className='navigation'>Home</b></NavLink>
          </NavItem>
          <NavItem className='pt-2'>
            <NavLink href="/about"><b className='navigation'>About</b></NavLink>
          </NavItem>
          <NavItem className='pt-2'>
            <NavLink href="/services"><b className='navigation'>Services</b></NavLink>
          </NavItem>
          <NavItem className='pt-2'>
           <NavLink href="/contact"><b className='navigation'>Contact</b></NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavBar;
