// pages/services.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal } from 'reactstrap';
import ServiceCard from '../components/Services/ServiceCard';
import ServiceModal from '../components/Services/ServiceModal';
import ContactSection from '../components/ContactUs';

const Services = () => {
  const [modal, setModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  const toggleModal = () => {
    setModal(!modal);
    setSelectedService(null);
  };

  const services = [
    { 
      id: 1, 
      title: 'Complete and Partial Dentures', 
      tag: 'Crafting personalized smiles with precision and care – our complete and partial dentures restore confidence and function,'+ 
          ' allowing you to embrace life\'s moments with a radiant smile. Discover bespoke denture solutions tailored to your unique needs.' 
    },
    { 
      id: 2, 
      title: 'Repairs and Relines', 
      tag: 'Renewing smiles with swift and efficient denture repairs, Precision Denture Clinic in Calgary offers expert relines and repairs to ensure your dentures stand the test of time.'+
          ' Experience prompt and reliable services, restoring both comfort to your daily life.' 
    },
    { 
      id: 3, 
      title: 'Denture Implants', 
      tag: 'Elevate your smile with increased security and comfort through denture implants. Experience optimal fit and retention, allowing you to enjoy a wide range of foods with confidence and ensuring a natural and secure feel.' 
    },
    { 
      id: 4, 
      title: 'Denture Cleaning', 
      tag: 'Maintain good oral hygiene and prolong the life of your dentures with professional denture cleaning services. Trust our expert care to keep your dentures in pristine condition, ensuring a healthy and confident smile every day.' 
    },
  ];

  const handleCardClick = (service) => {
    setSelectedService({ ...service });
    setModal(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = 'Precision Denture Clinic - Denture Services';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Explore our wide range of denture services designed to cater to your unique needs. From precision repairs to innovative implant-supported options, we are committed to enhancing your smile and oral health.';
    document.head.appendChild(metaDescription);
  }, []);
  return (
    <>
      <div className="position-relative service-header-container">
        <div className="service-header-background shadow"></div>
        <div className="overlay"></div>
        <Container className="text-light position-absolute top-50 start-50 text-center translate-middle">
          <h2>
            Elevating Smiles with Expert Denture Solutions Tailored Just for You
            {isLargeScreen && <br />} 
            in Calgary, Alberta
          </h2>
        </Container>
      </div>
      <Container className="services">
        <h2 className='text-center'></h2>
        <p className="text-center mt-4">
          Explore our wide range of denture services designed to cater to your unique needs. From precision repairs to innovative implant-supported options, we are committed to enhancing your smile and oral health.
        </p>
        <Row>
          {services.map((service) => (
            <Col key={service.id} md={6} className={`mb-4 ${selectedService?.id === service.id ? 'selected' : ''}`}>
              <ServiceCard service={service} onClick={() => handleCardClick(service)} />
            </Col>
          ))}
        </Row>
        <ServiceModal isOpen={modal} toggle={toggleModal} selectedService={selectedService} />
        <ContactSection />
      </Container>
    </>
  );
};

export default Services;

