// pages/contact.js
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ContactForm from '../components/Contact/ContactForm';
import ContactHeader from '../components/Contact/ContactHeader';
import LocationDetails from '../components/Contact/LocationDetails';
import PaymentOptions from '../components/Contact/PaymentOptions';

const Contact = () => {
  useEffect(() => {
    document.title = 'Contact Us - Precision Denture Clinic';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Contact Precision Denture Clinic to schedule an appointment. We would love to hear from you and respond to your inquiry as quickly as possible.';
    document.head.appendChild(metaDescription);
  }, []);
  return (
  <>
    <ContactHeader />
    <Container className='contact pt-4'>
      <Row>
        <LocationDetails />
        <Col md={{ size: 12, order: 1 }} lg={{ size: 6, order: 2 }} className="contact-column pb-4">
          <Row>
            <PaymentOptions />
          </Row>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </>
  );
};

export default Contact;
